// animacion boton

@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.whatsapp-container {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  .whatsapp-oculto {
    display: none;
    flex-direction: column;
    gap: 13px;
    align-items: flex-end;
    a {
      background-color: #4bca5a;
      display: block;
      padding: 14px;
      border-radius: 8px;
      text-decoration: none;
      display: inline-block;
      text-align: right;
      width: fit-content;
      img {
        width: 25px;
        height: 25px;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: white;
      }
    }
  }
  .whatsapp-oculto.activo {
    display: flex;
    animation: fade_in_show 0.5s;
  }
  .btn-whatsapp-flotante {
    display: block;
    border: none;
    background: none;
  }
}

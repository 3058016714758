.boxUbicacion {
  h1 {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 214.286% */
  }
  h2 {
    color: #000;

    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 214.286% */
  }

  p.sobre-la-zona {
    color: #9a9a9a;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 168%; /* 30.24px */
  }
}

.boxDescriptionArea {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;

  p {
    color: #000;
    text-align: center;
    font-family: "GothamBook";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 214.286% */
  }
}

.boxPlanificacion {
  padding-top: 100px;
  padding-bottom: 50px;
  h1 {
    color: #242527;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 155.556% */

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h2 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
}

.boxPropuestas {
  padding-top: 150px;
  padding-bottom: 100px;

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  h4 {
    color: #000;

    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h5 {
    color: #636363;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 312.5% */
  }

  ul {
    color: #636363;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 312.5% */
  }
}

.lista-propuestas {
  @media (max-width: 767px) {
    max-width: 200px;
  }
}

.btnVerMasmobile {
  max-width: 300px;

  @media (max-width: 767px) {
    max-width: 200px;
    margin-top: 10px;
  }

  @media (max-width: 400px) {
    max-width: 180px;
    margin-top: -40px;
  }
}

.boxAreaNew {
  height: 500px;
  transition: transform 0.5s ease;
  transform-origin: center; /* Zooms towards the center */
  @media (max-width: 767px) {
    height: 350px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
  }

  &.residencial {
    background-image: url("../assets/areas-assets/card-residencial.png");
    background-position: center center;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;

    @media (max-width: 767px) {
      background-size: contain;
    }
  }
  &.multifamiliar {
    background-image: url("../assets/areas-assets/card-multifamiliar.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
    @media (max-width: 767px) {
      background-size: contain;
    }
  }
  &.comercial {
    background-image: url("../assets/areas-assets/card-comercial.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
    @media (max-width: 767px) {
      background-size: contain;
    }
  }
}

.boxAreaNew:hover {
  transform: scale(1.1); /* Adjust scale value as needed */
}

.titleMobile {
  color: #004e59;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 30px;
  font-family: "GothamMedium";
}

.titleResidencialPrin {
  h1 {
    color: #004e59;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 30px;
    font-family: "GothamMedium";

    @media (max-width: 767px) {
      font-size: 28px;
      padding-bottom: 10px;
    }
  }
  h2 {
    color: #004e59;
    font-size: 100px;
    font-style: normal;
    line-height: 100px;
    font-weight: 700;
    line-height: 25px; /* 312.5% */
    font-family: "GothamBold";
    @media (max-width: 767px) {
      font-size: 48px;
      padding-top: 120px;
      line-height: 60px;
    }
  }
  h3 {
    color: #004e59;
    font-size: 76px;
    font-style: normal;
    line-height: 100px;
    font-weight: 700;
    line-height: 145px; /* 312.5% */
    font-family: "GothamBold";
    @media (max-width: 767px) {
      padding-top: 0px;
      font-size: 33px;
      line-height: 33px; /* 312.5% */
    }
  }

  &.contacto {
    h2 {
      color: #004e59;
      font-size: 80px;
      font-style: normal;
      line-height: 80px;
      font-weight: 700;
      line-height: 25px; /* 312.5% */
      font-family: "GothamBold";
      @media (max-width: 767px) {
        font-size: 48px;
        padding-top: 120px;
        line-height: 60px;
      }
    }
    h3 {
      color: #004e59;
      font-size: 46px;
      font-style: normal;
      line-height: 90px !important;
      font-weight: 700;
      line-height: 145px; /* 312.5% */
      font-family: "GothamBold";
      @media (max-width: 767px) {
        padding-top: 0px;
        font-size: 33px;
        line-height: 33px; /* 312.5% */
      }
    }
  }
}
.intro-residencial-txt {
  color: #000;
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
  font-weight: 300;
  padding-bottom: 30px;
}

.btn-planos-new {
  color: #fff;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  border-radius: 100px;
  width: 100%;
  font-size: 22px;

  background-color: #3b3f49;

  border: 2px solid #3b3f49;

  &.active {
    background-color: #3b3f49;
    color: #fff;
  }
}
.btn-planos-new:hover,
.btn-planos-new:focus,
.btn-planos-new:active {
  background-color: #666;

  border-color: #666;
  color: white;
  font-weight: bold;
}

.boxVentajasNew {
  background-color: #3b3f49;
  color: #fff;

  h6 {
    color: #fff;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;

    margin: 0;
  }
  .containerInterno {
    border: 1px solid #fff;
    border-radius: 12px;

    p {
      color: #fff;
      font-size: 22px;
      line-height: 26px;
      font-style: normal;
      font-weight: 400;
      padding-left: 25px;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}

.boxContactoNew {
  background-color: #3b3f49;
  color: #fff;
  position: relative;
  p {
    color: #fff;
    font-size: 22px;
    line-height: 26px;
    font-style: normal;
    font-weight: 400;
    font-family: "GothamBook";
    background-image: url("../assets/areas-assets/residencial/message-check-square.svg");
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 30px;
    margin: 0;
  }

  a {
    color: #fff;
  }

  .arrowUpRes {
    position: absolute;
    right: 20px;
    top: 40px;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.boxTitleIntro {
  position: absolute;
  bottom: -50px;
  left: 0;
  height: 240px;

  background-image: url("../assets/areas-assets/residencial/divisor.png");
  background-position: 0 bottom;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    background-image: none;
  }

  &.introNosotros {
    bottom: -150px;
    height: 320px;

    @media (max-width: 767px) {
      height: 220px;
    }
  }
}

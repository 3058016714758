@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "GothamBold";
  src: url("../assets/fonts/GothamBold.ttf") format("truetype");
}

@font-face {
  font-family: "GothamBook";
  src: url("../assets/fonts/GothamBook.ttf") format("truetype");
}

@font-face {
  font-family: "GothamMedium";
  src: url("../assets/fonts/GothamMedium.ttf") format("truetype");
}

body {
  font-family: "GothamBook", sans-serif;

  overflow-x: hidden !important;
}

//MORE
@import "./variables";
@import "./global";
@import "./slider.scss";
@import "./intro.scss";
@import "./footer.scss";

//SECTIONS
@import "./areas.scss";
@import "./ubicacion.scss";

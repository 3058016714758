.boxFooter {
  // object-fit: contain;

  bottom: 0;

  &.noBg {
    background-image: none;
    padding-top: 0px;
  }

  .bgNegro {
    background-color: #000;
    padding-top: 20px;
  }

  .fraseFooter {
    color: #fff;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .boxIzqInfo {
    max-width: 170px;
  }

  .ubicacionFooter {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    li {
      padding-bottom: 10px;
    }

    a {
      color: #c4c4c4;
      text-align: center;
      font-size: 13px;
      font-style: normal;

      line-height: normal;
      text-decoration: none;

      &.active {
        color: #fff;
        font-weight: 700;
      }
    }

    a:hover {
      color: #fff;
      font-weight: 700;
    }
  }

  .border-bottom-footer {
    border-bottom: 1px solid rgb(82, 82, 82);
  }

  .copyright {
    color: #c4c4c4;
    text-align: center;

    font-size: 14.616px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;

    a {
      color: #c4c4c4;
      text-decoration: none;
    }
  }
}

.boxIzquierda-Footer {
  h5 {
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 767px) {
      font-size: 30px;
    }
  }
}

.contactList {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: 90px;

  @media (max-width: 767px) {
    padding-left: 20px;
    padding-top: 20px;
  }

  li {
    padding-bottom: 20px;
  }

  a {
    color: #000;

    line-height: normal;
    text-decoration: none;
  }
}

.links-footer-izq {
  li {
    padding-bottom: 13px;
    a {
      color: #c4c4c4;
      text-align: center;
      font-size: 13px;
      font-style: normal;

      line-height: normal;
      text-decoration: none;

      &.active {
        color: #fff;
        font-weight: 700;
      }
    }

    a:hover {
      color: #fff;
      font-weight: 700;
    }
  }
}

.boxForm {
  padding-bottom: 10px;
}

.form-label {
  color: #000;

  font-size: 20.879px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.879px; /* 100% */
}

.form-control {
  background-color: #fff !important ;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  outline: none !important;
  box-shadow: none !important;
  color: #fff !important;
  padding-left: 0 !important;
}

.form-control:focus {
  outline: none !important;
  border-bottom: 1px solid green;
  box-shadow: none !important;
}

.form-control::placeholder {
  color: #8d8d8d;
}

.form-control::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #8d8d8d;
}

.isWhite {
  .boxIzquierda-Footer {
    h5 {
      color: #000 !important;
    }

    .contactList {
      color: #000 !important;

      li {
        padding-bottom: 30px;
      }
    }
  }

  .links-footer-izq {
    li {
      a {
        color: #000 !important;

        &.active {
          color: #000 !important;
        }
      }

      a:hover {
        color: #000 !important;
      }
    }
  }

  .boxForm {
    padding-bottom: 120px;
  }

  .form-label {
    color: #000 !important;
  }

  .form-control {
    background-color: #fff !important ;

    border-bottom: 1px solid #000 !important;

    color: #000 !important;
    padding-left: 0 !important;
  }

  .form-control:focus {
    outline: none !important;
    border-bottom: 1px solid cyan;
    box-shadow: none !important;
  }

  .form-control::placeholder {
    color: #8d8d8d;
  }

  .form-control::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #8d8d8d;
  }
}

.form-control {
  color: #000 !important;
}

.boxDatosContacto {
  font-size: 18px;
}

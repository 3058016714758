.intro-welcome {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 100px;

  @media (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h3 {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 767px) {
      font-size: 34px;
    }
  }

  p {
    color: #000;
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
}

.boxIntro {
  max-width: 227px;
  margin: 0 auto;
  margin-bottom: 80px;

  h5 {
    color: #242527;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  p {
    color: #636363;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.boxApertura {
  background-image: url("../assets/01_Home/apertura.jpg");
  background-position: center top;
  background-repeat: no-repeat;

  object-fit: contain;
  padding-top: 83px;

  .bgGris {
    background-color: #ececec;
  }

  .boxVideo {
    padding: 100px;

    @media (max-width: 767px) {
      padding: 50px 0 0 0;
    }
  }
}

.boxAspecto {
  padding: 20px 40px;
  height: 744px;
  padding-top: 190px;
  text-decoration: none !important;

  @media (max-width: 767px) {
    margin-top: 80px;
  }

  h5 {
    color: #fff;
    font-size: 36.094px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    // background-image: url("../assets/01_Home/arrow.png");
    // background-position: 290px top;
    // background-repeat: no-repeat;
    text-decoration: none !important;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.6);

    @media (max-width: 767px) {
      font-size: 30px;
      background-position: 100% top;
    }
  }

  p {
    color: #fff;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 400px;
    text-decoration: none !important;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.6);
  }
  &.residencial {
    background-image: url("../assets/01_Home/residenciales.png");
    background-position: center top;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: cover;
  }
  &.multifamiliar {
    background-image: url("../assets/01_Home/multifamiliar.png");
    background-position: center top;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: cover;
  }
  &.comercial {
    background-image: url("../assets/01_Home/comercial.png");
    background-position: center top;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: cover;
  }
}

.boxAspecto:hover {
  opacity: 0.8;
}

.boxActividades {
  padding-top: 200px;
  padding-bottom: 200px;

  @media (max-width: 767px) {
    padding-top: 120px;
    padding-bottom: 50px;
  }

  h2 {
    color: #242527;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    padding-bottom: 30px;

    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  p {
    color: #9a9a9a;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 168%;
    padding-bottom: 30px;
  }

  .boxTextActividades {
    padding-left: 70px;
    padding-right: 70px;

    @media (max-width: 767px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.rowActividades {
  padding-top: 200px;

  @media (max-width: 767px) {
    padding-top: 100px;
  }
}

.accordion-header {
  button {
    font-size: 20px !important;
    font-family: "GothamMedium" !important;
  }
}

/* Style when expanded */
.accordion-button[aria-expanded="true"] {
  background-color: lightgray; /* Example expanded background color */
  /* Other styling for expanded state */
}

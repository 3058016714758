.navbar-toggler {
  border-color: white !important;

  .navbar-toggler-icon {
    color: white !important;
    background-image: url("../assets/01_Home/icon-toggle.png");
  }
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.slider {
  .container {
    height: 100% !important;
  }
  &.home {
    padding: 0 !important;
    margin: 0 !important;
    // background-image: url("../assets/01_Home/header.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    height: 860px;
    object-fit: contain;

    @media (max-width: 767px) {
      //   background-image: url("../assets/01_Home/mobile_header.jpg");
      //min-height: 700px;
    }

    @media (max-width: 1367px) {
      //    background-image: url("../assets/01_Home/header-1920.jpg");
      //min-height: 700px;
    }

    @media (max-height: 767px) {
      //    background-image: url("../assets/01_Home/header-1920.jpg");
      min-height: 700px;
    }
  }

  &.areas {
    background-image: url("../assets/areas-assets/bg.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 1000px;
    object-fit: contain;

    @media (max-width: 767px) {
      background-size: cover;
    }

    @media (max-width: 1367px) {
      //    background-image: url("../assets/01_Home/header-1920.jpg");
      //min-height: 700px;
    }

    @media (max-height: 767px) {
      //    background-image: url("../assets/01_Home/header-1920.jpg");
      min-height: 700px;
    }
  }

  &.contactoNew {
    background-image: url("../assets/contacto-asset/slider.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 1080px;
    object-fit: contain;
  }

  &.nosotrosNew {
    background-image: url("../assets/nosotros-assets/slider.png");
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 1080px;

    background-size: cover;

    @media (max-width: 767px) {
      min-height: 950px;
    }

    @media (min-width: 1367px) {
      min-height: 1400px;
    }

    // @media (max-height: 767px) {
    //   //    background-image: url("../assets/01_Home/header-1920.jpg");
    //   min-height: 700px;
    // }
  }

  &.residencialInterno {
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 1080px;
    object-fit: contain;
    background-image: url("../assets/areas-assets/residencial/intro.jpg");
    background-position: center center;
    background-size: cover;

    @media (max-width: 767px) {
      min-height: 480px;
      background-size: contain;
    }

    @media (max-width: 1367px) {
      min-height: 780px;
      background-size: cover;
    }
  }

  &.multifamiliarInterno {
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 1080px;
    object-fit: contain;
    background-image: url("../assets/areas-assets/multifamiliar/intro.jpg");
    background-position: center center;
    background-size: cover;

    @media (max-width: 767px) {
      min-height: 480px;
      background-size: contain;
    }

    @media (max-width: 1367px) {
      min-height: 780px;
      background-size: cover;
    }
  }

  &.comercialInterno {
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 1080px;
    object-fit: contain;
    background-image: url("../assets/areas-assets/comercial/slider.jpg");
    background-position: center center;
    background-size: cover;

    @media (max-width: 767px) {
      min-height: 480px;
      background-size: contain;
    }

    @media (max-width: 1367px) {
      min-height: 780px;
      background-size: cover;
    }
  }

  &.multifamiliar {
    background-image: url("../assets/03_Multifamiliar/Header_Multifamiliar.png");
    background-position: center top;
    background-repeat: no-repeat;
    height: 885px;
    object-fit: contain;

    @media (max-width: 767px) {
      background-image: url("../assets/03_Multifamiliar/mobile_header.jpg");
      height: 585px;
    }

    @media (min-width: 1367px) {
      background-image: url("../assets/03_Multifamiliar/Header_Multifamiliar.png");
    }
  }

  &.comercial {
    background-image: url("../assets/04_Comercial/header_comercial.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    height: 885px;
    object-fit: contain;

    @media (max-width: 767px) {
      background-image: url("../assets/04_Comercial/mobile_header.jpg");
      height: 585px;
    }

    @media (min-width: 1367px) {
      background-image: url("../assets/04_Comercial/header_comercial.jpg");
    }
  }

  &.residencial {
    background-image: url("../assets/02_Residencial/Header_Residencial.png");
    background-position: center top;
    background-repeat: no-repeat;
    height: 885px;
    object-fit: contain;

    @media (max-width: 767px) {
      background-image: url("../assets/02_Residencial/mobile_header.jpg");
      height: 585px;
    }

    @media (min-width: 1367px) {
      background-image: url("../assets/02_Residencial/Header_Residencial.png");
    }
  }

  &.ubicacion {
    background-image: url("../assets/05_Ubicacion/header_ubicacion.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    height: 532px;
    object-fit: contain;

    @media (max-width: 767px) {
      background-image: url("../assets/05_Ubicacion/mobile_header.jpg");
      height: 590px;
    }
  }

  &.contacto {
    background-image: url("../assets/06_contacto/header_contacto.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    height: 532px;
    object-fit: contain;

    @media (max-width: 767px) {
      background-image: url("../assets/06_contacto/mobile_header.jpg");
      height: 590px;
    }
  }
}

.navHeader {
  .nav-link {
    text-align: center;
    color: #fff;
    font-size: 28px;
    font-style: normal;
    margin: 0px 20px 0px 20px;
    line-height: normal;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    &.active {
      font-weight: 700;
      color: #fff;
      text-decoration: underline !important;
    }
  }
}

.nav-link:hover {
  color: #fff;
}

.socialList {
  li {
    height: 30px;
    width: 40px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .linkSocial {
    color: #fff;
    cursor: pointer !important;
  }

  .linkSocial:hover {
    color: #fff;
  }
}

.btn-black {
  color: #fff;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  border-radius: 0px;

  background-color: #000;

  border: 2px solid #000;

  &.active {
    background-color: #000;
    color: #fff;
  }
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active {
  background-color: #666;

  border-color: #666;
  color: white;
  font-weight: bold;
}

.text-contacto {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 214.286% */
}

.cursor-pointer {
  cursor: pointer !important;
}

.btnPdf {
  color: #fff;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  border-radius: 0px;
  background-color: #6d6d6d;
  border: 2px solid #6d6d6d;
  text-decoration: none;
  min-width: 190px;
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  padding-left: 47px;

  background-image: url("../assets/pdfIcon.png");
  background-position: 10px center;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    max-width: 300px;
  }

  &.active {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
}
.btnPdf:hover,
.btnPdf:focus,
.btnPdf:active {
  background-color: #000;

  border-color: #000;
  color: white;
  font-weight: bold;
}

.imgContainer {
  object-fit: cover;
  width: 100% !important;
  max-height: 1000px;
}

#videodesktop {
  width: 100vw;
  height: 100vh;
  min-height: 1000px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: -200px;
  bottom: 0;
  z-index: -1;
  -webkit-box-shadow: 0px 10px 34px -3px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 10px 34px -3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 10px 34px -3px rgba(0, 0, 0, 0.29);
}

.navbar-brand {
  @media (max-width: 767px) {
    img {
      max-width: 100px !important;
    }
  }
}
